// When factset has a new updates to their widgets we can download from https://rwbaird-widgets.services.production.gateway.factset.com/lib/widgets-wc.js amd add the script to the assets folder
import { logError } from "#app/lib/logger";
import { memo, useEffect, useId, useMemo, useState } from "react";

/**
 * Extends the JSX namespace to include custom FactSet web components.
 * 
 * This declaration allows TypeScript to recognize the custom elements used in the application.
 * 
 * Each custom element is typed as `any` to allow for flexible usage within the application.
 */
declare namespace JSX {
  interface IntrinsicElements {
    'fds-alert-history-list': any;
    'fds-alert-trigger-contols': any;
    'fds-alert-triggers-list': any;
    'fds-asset-list-performance': any;
    'fds-assets-list': any;
    'fds-calendar-event-detail': any;
    'fds-calendar-events-list': any;
    'fds-chart-roulette': any;
    'fds-company-board-and-management': any;
    'fds-company-contact': any;
    'fds-company-description': any;
    'fds-company-key-items': any;
    'fds-company-key-items-extended': any;
    'fds-estimates-chart': any;
    'fds-estimates-data': any;
    'fds-extended-price-data': any;
    'fds-factsheet-header': any;
    'fds-key-figures': any;
    'fds-news-detail': any;
    'fds-news-list': any;
    'fds-portfolio': any;
    'fds-price-data': any;
    'fds-search-suggester': any;
    'fds-stock-analysts-recommendation': any;
    'fds-stock-analysts-recommendations-matrix': any;
    'fds-stock-most-traded-instruments': any;
    'fds-stock-top-flop': any;
    'fds-time-frame-chart': any
  }
}
/**
 * Extracts the suffix of JSX intrinsic elements that start with 'fds-'.
 * 
 * This type alias uses conditional types to filter and extract the part of the 
 * intrinsic element names that follow the 'fds-' prefix.
 * 
 * This type ensures that only custom FactSet web components added to IntrisnicElements can be used whererever this type is declared.
 * 
 * @example
 * // Assuming JSX.IntrinsicElements contains 'fds-button', 'fds-input', and 'div'
 * type Example = FactSetElements; // 'button' | 'input'
 */
type FactSetElements = keyof JSX.IntrinsicElements extends infer K
  ? K extends string
  ? K extends `fds-${infer Rest}`
  ? Rest
  : never
  : never
  : never;

export type ConfigItem =
  | { type: "attribute"; key: string; value: unknown }
  | { type: "event"; key: string; value: Function };

type FactSetWidgetProps = {
  config: ConfigItem[];
  errorElement: React.ReactNode;
  pendingElement: React.ReactNode;
  widget: FactSetElements;
};

type FactSetStoreEvent = {
  detail: Error[];
}

const FactSetWidget = memo(({
  config,
  errorElement,
  pendingElement,
  widget
}: FactSetWidgetProps) => {
  const [wigetState, setWidgetState] = useState({
    isFatal: false,
    isError: false,
    isPending: true
  });
  const widgetId = useId();

  const widgetElement = useMemo(() => {
    const attributes = config.filter((el) => el.type === "attribute");
    const events = config.filter((el) => el.type === "event");
    const element = document.createElement(`fds-${widget}`) as any;
    if (element) {
      if (attributes.length > 0) {
        for (const attr of attributes) {
          element[attr.key] = attr.value;
        }
      }
      if (events.length > 0) {
        for (const ev of events) {
          element.addEventListener(ev.key, ev.value);
        }
      }
      element["locale"] = "en-US";
      element.addEventListener("store-fatal", ({ detail }: FactSetStoreEvent) => {
        logError(detail[0], `(${widget}) FactSet Fatal: ${detail[0]}`);
        setWidgetState(curr => ({
          ...curr,
          isFatal: true,
          isPending: false
        }));
      });
      element.addEventListener("store-error", ({ detail }: FactSetStoreEvent) => {
        logError(detail[0], `(${widget}) FactSet Error: ${detail[0]}`);
        setWidgetState(curr => ({
          ...curr,
          isError: true,
          isPending: false
        }));
      });
      element.addEventListener("data-load", () => {
        setWidgetState(curr => ({
          ...curr,
          isPending: false
        }));
      });
    }
    return element;
  }, [config, widget]);

  useEffect(() => {
    document.getElementById(widgetId)?.appendChild(widgetElement);
    return () => {
      document.getElementById(widgetId)?.removeChild(widgetElement);
    };
  }, [widgetElement, widgetId]);

  if (wigetState.isFatal || wigetState.isError) {
    return errorElement;
  }

  return (
    <div id={widgetId}>
      {wigetState.isPending ? pendingElement : null}
    </div>
  );
});

FactSetWidget.displayName = "FactSetWidget";

export { FactSetWidget };

